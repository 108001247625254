// src/components/ProductCard.tsx

import React from "react";
import { Product } from "../interfaces/product-interface";
import { Link } from "react-router-dom";
import pb from "../pocketbase";

interface ProductCardProps {
  product: Product;
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
  const imageUrl =
    product.images && product.images.length > 0
      ? pb.getFileUrl(product, product.images[0])
      : "https://via.placeholder.com/300x200?text=Sem+Imagem";

  return (
    <Link to={`/product/${product.id}`} className="block">
      <div className="border rounded-lg overflow-hidden shadow hover:shadow-lg transition-shadow bg-white">
        <img
          src={imageUrl}
          alt={product.name}
          className="w-full h-48 sm:h-56 md:h-64 lg:h-72 xl:h-80 object-cover"
        />
        <div className="p-2 sm:p-4  h-28 rounded-lg">
          <div className="rounded-lg">
            <h2
              className={`font-semibold  text-gray-800 hover:text-blue-600 transition-colors truncate  text-sm`}
            >
              {product.name}
            </h2>
          </div>

          <button className="mt-4 w-full py-2 px-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors">
            Adquirir
          </button>
        </div>
      </div>
    </Link>
  );
};

export default ProductCard;
