import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import pb from "../pocketbase";
import { Product } from "../interfaces/product-interface";
import { FaStar } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import { Swiper as SwiperType } from "swiper"; // Importando o tipo do Swiper
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const ProductDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [product, setProduct] = useState<Product | null>(null);
  const [selectedImage, setSelectedImage] = useState<string>("");

  const swiperRef = useRef<SwiperType | null>(null);

  useEffect(() => {
    pb.collection("products")
      .getOne<Product>(id as string, { expand: "category,tags" })
      .then((res) => {
        setProduct(res);
        if (res.images && res.images.length > 0) {
          setSelectedImage(pb.getFileUrl(res, res.images[0]));
        }
      })
      .catch((err) => console.error(err));
  }, [id]);

  if (!product) return <div>Carregando...</div>;

  const imageUrls = product.images.map((image) =>
    pb.getFileUrl(product, image)
  );

  const handleThumbnailClick = (url: string, index: number) => {
    setSelectedImage(url);
    if (swiperRef.current) {
      swiperRef.current.slideTo(index);
    }
  };

  return (
    <div className="container  mx-auto px-4 py-8">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Galeria de imagens com carrossel */}
        <div className="lg:w-8/12 xl:ml-52 md:ml-5">
          <div className="border rounded-md  overflow-hidden mb-4 select-none">
            <Swiper
              onSwiper={(swiper: SwiperType) => (swiperRef.current = swiper)}
              spaceBetween={10}
              slidesPerView={1}
              pagination={{ clickable: true }}
              navigation
              modules={[Pagination, Navigation]}
              className="w-full h-64 sm:h-80 md:h-96"
            >
              {imageUrls.map((url, index) => (
                <SwiperSlide key={index}>
                  <img
                    src={url}
                    alt={`${product.name} ${index + 1}`}
                    className="w-full h-full object-contain object-center rounded-md outline-none"
                    onDragStart={(e) => e.preventDefault()} // Evitar arrastar a imagem
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          {/* Miniaturas abaixo do carrossel */}
          <div className="flex flex-wrap gap-2 mt-4">
            {imageUrls.map((url, index) => (
              <img
                key={index}
                src={url}
                alt={`Miniatura ${index + 1}`}
                onClick={() => handleThumbnailClick(url, index)}
                className={`w-16 h-16 object-cover border cursor-pointer rounded-md ${
                  selectedImage === url ? "border-blue-500" : "border-gray-300"
                }`}
              />
            ))}
          </div>
        </div>

        {/* Detalhes do produto */}
        <div className="lg:w-5/12">
          <h1 className="text-3xl font-bold mb-2">{product.name}</h1>
          {product.expand?.category && (
            <p className="text-gray-600 mb-4">
              Categoria: {product.expand.category.name}
            </p>
          )}
        
          {product.materialType && (
            <p className="text-gray-700 mb-2">
              <span className="font-semibold">Material:</span>{" "}
              {product.materialType}
            </p>
          )}
          {product.size && product.size.length > 0 && (
            <p className="text-gray-700 mb-2">
              <span className="font-semibold">Tamanhos Disponíveis:</span>{" "}
              {product.size.join(", ")}
            </p>
          )}
     
          <div className="mt-6">
            <h2 className="text-lg font-semibold mb-2">Descrição do Produto</h2>
            <div
              className="text-gray-700 leading-relaxed"
              dangerouslySetInnerHTML={{ __html: product.description || "" }}
            />
          </div>
          {product.available ? (
            <a
              href={product.link}
              target="_blank"
              rel="noopener noreferrer"
              className="block w-80 my-7 text-center bg-green-500 text-black font-bold py-3 rounded-md hover:bg-yellow-600 transition-colors mb-4"
            >
              Comprar agora
            </a>
          ) : (
            <div className="block w-full text-center bg-gray-400 text-white font-bold py-3 rounded-md mb-4">
              Produto Indisponível
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
