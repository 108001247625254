// src/components/Contact.tsx

import React from 'react';
import { FaInstagram, FaFacebookF, FaWhatsapp, FaShoppingCart } from 'react-icons/fa';

const Contact: React.FC = () => {
  return (
    <div className="container mx-auto py-10 px-4">
      <h1 className="text-4xl font-bold text-center mb-8">Nossos Contatos</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
        <a
          href="https://www.instagram.com/diggy.3d"
          target="_blank"
          rel="noopener noreferrer"
          className="flex flex-col items-center bg-pink-100 p-4 rounded-lg hover:bg-pink-200 transition"
        >
          <FaInstagram className="text-5xl text-pink-500 mb-2" />
          <span className="text-xl font-semibold text-pink-600">Instagram</span>
        </a>
        <a
          href="https://www.facebook.com/profile.php?id=61563904411231"
          target="_blank"
          rel="noopener noreferrer"
          className="flex flex-col items-center bg-blue-100 p-4 rounded-lg hover:bg-blue-200 transition"
        >
          <FaFacebookF className="text-5xl text-blue-700 mb-2" />
          <span className="text-xl font-semibold text-blue-800">Facebook</span>
        </a>
        <a
          href="https://wa.me/553183159183?text=Gostaria%20de%20mais%20informações"
          target="_blank"
          rel="noopener noreferrer"
          className="flex flex-col items-center bg-green-100 p-4 rounded-lg hover:bg-green-200 transition"
        >
          <FaWhatsapp className="text-5xl text-green-500 mb-2" />
          <span className="text-xl font-semibold text-green-600">WhatsApp</span>
        </a>
        <a
          href="https://www.mercadolivre.com.br/perfil/DIGGY"
          target="_blank"
          rel="noopener noreferrer"
          className="flex flex-col items-center bg-yellow-100 p-4 rounded-lg hover:bg-yellow-200 transition"
        >
          <FaShoppingCart className="text-5xl text-yellow-500 mb-2" />
          <span className="text-xl font-semibold text-yellow-600">Mercado Livre</span>
        </a>
      </div>
    </div>
  );
};

export default Contact;
