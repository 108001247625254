// src/components/Footer.tsx

import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="bg-gray-700  text-white py-8">
   <div className="flex flex-col md:flex-row justify-center ">
  {/* Seção superior do footer */}
  <div className="flex flex-col md:flex-row   md:gap-5 ">
    {/* Coluna 1 */}
    <div className="flex-1">
      <h5 className="font-bold mb-2 text-center">Conheça a Nossa Empresa</h5>
      <ul>
        <li>
          <a href="#" className="text-gray-400 hover:text-white flex justify-center">Sobre Nós</a>
        </li>
      </ul>
    </div>

    {/* Coluna 2 */}
    <div className="flex-1 min-w-max">
      <h5 className="font-bold mb-2 text-center">Atendimento ao Cliente</h5>
      <ul>
        <li>
          <a href="https://wa.me/553183159183" className="text-gray-400 hover:text-white flex justify-center">Fale Conosco</a>
        </li>
        <li>
          <a href="#" className="text-gray-400 hover:text-white flex justify-center">FAQ</a>
        </li>
      </ul>
    </div>

    {/* Coluna 3 */}
    <div className="flex-1 min-w-max">
      <h5 className="font-bold mb-2 text-center">Negócios</h5>
      <ul>
        <li>
          <a href="#" className="text-gray-400 hover:text-white flex justify-center">Serviços 3D</a>
        </li>
      </ul>
    </div>

    {/* Coluna 4 */}
    <div className="flex-1 min-w-max">
      <h5 className="font-bold mb-2 text-center">Conecte-se Conosco</h5>
      <ul>
        <li>
          <a href="https://www.facebook.com/profile.php?id=61563904411231" className="text-gray-400 flex justify-center hover:text-white">Facebook</a>
        </li>
        <li>
          <a href="https://www.instagram.com/diggy.3d" className="text-gray-400 hover:text-white flex justify-center">Instagram</a>
        </li>
      </ul>
    </div>
  </div>
</div>

     {/* Seção inferior do footer */}
      <div className="mt-8 border-t border-gray-700 text-center  text-gray-400">
        <p>&copy; {new Date().getFullYear()} Diggy 3D. Todos os direitos reservados.</p>
      </div>
  </footer>
  
  );
};

export default Footer;
