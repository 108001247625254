import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useSearchParams, useLocation } from "react-router-dom";
import pb from "../pocketbase";
import { Resource } from "../interfaces/resource-interface";
import { Category } from "../interfaces/category-interface";
import "@fortawesome/fontawesome-free/css/all.min.css";

const Header: React.FC = () => {
  const [logoUrl, setLogoUrl] = useState<string | null>(null);
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [resources, fetchedCategories] = await Promise.all([
          pb.collection("resources").getFullList<Resource>(1),
          pb.collection("category").getFullList<Category>(200, {
            filter: "status=true",
          }),
        ]);

        if (resources.length > 0) {
          const resource = resources[8];
          setLogoUrl(pb.getFileUrl(resource, resource.image));
        }

        setCategories(fetchedCategories);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const categoryId = params.get("category") || "";
    const query = params.get("q") || "";

    setSelectedCategory(categoryId);
    setSearchValue(query);
  }, [location]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCategoryChange = (categoryId: string) => {
    setSearchValue("");
    setSelectedCategory(categoryId);
    searchParams.set("category", categoryId || "");
    navigate({ search: searchParams.toString() });
    setIsDropdownOpen(false);
  };

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSelectedCategory("");
    searchParams.set("q", searchValue);
    navigate({ search: searchParams.toString() });
  };

  const handleMenuLinkClick = () => {
    setIsMenuOpen(false); // Fecha o menu ao clicar em um link
  };

  return (
    <header className="bg-gray-700 sticky z-50 top-0 w-full text-white">
      <div className="flex justify-between items-center w-full max-w-6xl mx-auto p-4">
        <div className="grid grid-cols-2 md:grid-cols-3 grid-rows-1 items-center">
          <Link to="/" className="flex items-center">
            {logoUrl ? (
              <img src={logoUrl} alt="Logo" className="w-14 h-14 sm:w-20 sm:h-20 object-cover" />
            ) : (
              <h1 className="text-2xl sm:text-3xl font-bold">Diggy</h1>
            )}
          </Link>

          <div className="hidden xl:flex gap-5 col-span-2">
            <Link to="/" className="font-bold">Página inicial</Link>
            <Link to="/" className="font-bold">Ofertas</Link>
            <Link to="/" className="font-bold">Sobre</Link>
            <Link to="/contato" className="font-bold">Contato</Link>
          </div>

          <button
            className="xl:hidden flex justify-center"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            aria-label="Abrir menu"
          >
            <i className="fas fa-bars text-white"></i>
          </button>
        </div>

        <div className="relative">
          <form onSubmit={handleSearchSubmit} className="flex items-center bg-gray-100 text-black p-2 rounded-md w-42 lg:w-max">
            <div className="relative" ref={dropdownRef}>
              <button
                type="button"
                onClick={() => setIsDropdownOpen((prev) => !prev)}
                className="bg-white lg:flex text-black py-1 px-2 rounded-l-md border-r border-gray-300 w-7 lg:w-28 text-left items-center"
                aria-haspopup="listbox"
                aria-expanded={isDropdownOpen}
                aria-label="Selecionar categoria"
              >
                <h6 className="bg-white hidden lg:flex">Categorias</h6>
                <svg className={`w-4 h-4 transform transition-transform ${isDropdownOpen ? "rotate-180" : "rotate-0"}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </button>

              {isDropdownOpen && (
                <div className="absolute left-0 mt-1 bg-white border border-gray-300 rounded-md shadow-lg z-10" role="listbox">
                  <ul className="max-h-40 overflow-auto">
                    <li onClick={() => handleCategoryChange("")} className="cursor-pointer p-2 hover:bg-gray-200">Categorias</li>
                    {categories.map((category) => (
                      <li key={category.id} onClick={() => handleCategoryChange(category.id)} className="cursor-pointer p-2 hover:bg-gray-200">
                        {category.name}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            <input
              type="text"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Pesquisar"
              className="flex-1 bg-transparent outline-none px-2 md:min-w-[150px] lg:min-w-[250px]"
              aria-label="Pesquisar"
            />

            <button type="submit" className="px-2" aria-label="Buscar">
              <img src="/search.png" alt="Buscar" width={16} height={16} />
            </button>
          </form>
        </div>
      </div>

      {isMenuOpen && (
        <div className="flex flex-col bg-gray-600 p-4 xl:hidden">
          <Link to="/" className="font-bold py-2" onClick={handleMenuLinkClick}>Página inicial</Link>
          <Link to="/" className="font-bold py-2" onClick={handleMenuLinkClick}>Ofertas</Link>
          <Link to="/" className="font-bold py-2" onClick={handleMenuLinkClick}>Sobre</Link>
          <Link to="/contato" className="font-bold py-2" onClick={handleMenuLinkClick}>Contato</Link>
        </div>
      )}
    </header>
  );
};

export default Header;
