// src/App.tsx
import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import ProductDetail from "./components/ProductDetail";
import Header from "./components/Header";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import './index.css';
const App: React.FC = () => {
  return (
    <div className="app-container flex flex-col min-h-screen">
      {/* Header */}
      <Header />

      {/* Banner Principal
      <div className="banner bg-gray-500 text-white text-center py-4">
        <h1 className="text-3xl font-bold">Bem-vindo à nossa Loja!</h1>
      </div> */}

      {/* Banner de Destaque de Produto */}
      {/* <div className="product-highlight bg-gray-400 text-black text-center py-4">
        <h2 className="text-2xl font-semibold">🔥 Produto em Destaque 🔥</h2>
        <p>Não perca! Desconto imperdível por tempo limitado.</p>
      </div> */}

      {/* Conteúdo principal com asides */}
      <div className="flex flex-grow">
        {/* Left Aside
        <aside className="left-aside bg-gray-200 w-1/4 p-4">
          <h2 className="font-bold text-lg">Categorias</h2>
          <p>Lista de categorias e outras informações...</p>
        </aside> */}

        {/* Conteúdo principal */}
        <main className="low-content flex-grow m-auto bg-white">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/product/:id" element={<ProductDetail />} />
            <Route path="/contato" element={<Contact />} />
          </Routes>
        </main>

        {/* Right Aside
        <aside className="right-aside bg-gray-200 w-1/4 p-4">
          <h2 className="font-bold text-lg">Novidades</h2>
          <p>Produtos em destaque e promoções.</p>
        </aside> */}
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default App;
