import React, { useEffect, useState } from "react";
import pb from "../pocketbase";
import ProductCard from "./ProductCard";
import { Product } from "../interfaces/product-interface";
import { useSearchParams } from "react-router-dom";

const Home: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const PRODUCTS_PER_PAGE = 10;

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setIsLoading(true);
        setErrorMessage(null); // Limpa mensagens de erro anteriores

        const categoryId = searchParams.get("category");
        const query = searchParams.get("q");

        let filter = "";
        if (categoryId) filter += `category ~ "${categoryId}"`;
        if (query) 
          filter += `${filter ? " && " : ""}(name ~ "${query}" || description ~ "${query}" || tags.name ~ "${query}")`;

        const response = await pb.collection("products").getList<Product>(page, PRODUCTS_PER_PAGE, {
          filter,
          expand: "category,tags",
        });

        const visibleProducts = response.items.filter(product => !product.hide);
        setProducts(visibleProducts);
        setTotalPages(response.totalPages);
      } catch (err) {
        console.error("Erro ao buscar produtos:", err);
        setErrorMessage("Erro ao carregar produtos. Tente novamente mais tarde."); // Define uma mensagem de erro
      } finally {
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, [searchParams, page]);

  const handleNextPage = () => {
    if (page < totalPages) setPage(prevPage => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (page > 1) setPage(prevPage => prevPage - 1);
  };

  return (
    <div className="container mx-auto px-2 sm:px-4 py-4 sm:py-8">
      {isLoading ? (
        <p className="text-center">Carregando produtos...</p>
      ) : (
        <>
          {errorMessage && (
            <p className="text-red-500 text-center">{errorMessage}</p>
          )}
          {products.length === 0 ? (
            <p className="text-center text-gray-500">
              Nenhum produto encontrado.
            </p>
          ) : (
            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 sm:gap-6 md:gap-8 item">
              {products.map(product => (
                <ProductCard key={product.id} product={product} />
              ))}
            </div>
          )}
          <div className="flex justify-center items-center mt-6 space-x-4">
            <button
              onClick={handlePrevPage}
              disabled={page === 1}
              className={`px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded ${page === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
            >
              Anterior
            </button>
            <span>
              Página {page} de {totalPages}
            </span>
            <button
              onClick={handleNextPage}
              disabled={page === totalPages}
              className={`px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded ${page === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
            >
              Próxima
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Home;
